<template>
  <section id="section-service">
    <div v-scrollanimation="'up'" class="nstra-container service-container">
      <div class="section-header">
        <h3 class="section-title">
          What can we do
          <span class="text-primary">to help.</span>
        </h3>
      </div>
      <div class="section-body">
        <b-row v-scrollanimation="'up'" class="service-item align-self-center" cols="1" cols-md="2">
          <b-col>
            <img width="500" class="service-image reguler-shadow reguler-border-radius" src="@/assets/images/services/img-service-1.jpeg" alt="service image" />
          </b-col>
          <b-col v-scrollanimation="'up'" class="d-flex flex-column service-overview text-left">
            <span class="service-title text-primary">Web Development</span>
            <h4 class="service-subtitle">
              Create beautiful and responsive web application on a variety of devices and screen sizes.
            </h4>
            <p class="service-desc">
              Whatever size device and whatever the browser viewport size, your web application will render properly and deliver a great user experience
            </p>
            <div class="technologies d-flex flex-row">
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-react.png" alt="react" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-angular.png" alt="angular" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-vue.png" alt="vue" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-springboot.png" alt="springboot" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-zk.png" alt="zk" />
            </div>
          </b-col>
        </b-row>
        <b-row v-scrollanimation="'up'" class="service-item align-self-center flex-row-reverse" cols="1" cols-md="2">
          <b-col>
            <img width="500" class="service-image reguler-shadow reguler-border-radius" src="@/assets/images/services/img-service-2.jpeg" alt="service image" />
          </b-col>
          <b-col v-scrollanimation="'up'" class="d-flex flex-column service-overview text-left">
            <span class="service-title text-primary">Mobile Development</span>
            <h4 class="service-subtitle">
              Reach consumers via tablets and smartphones
            </h4>
            <p class="service-desc">
              Android, iOS and cross-platform mobile solutions all require a well-defined mobility strategy. Nostratech has guided companies with effective strategy, product roadmap & development and
              execution assistance.
            </p>
            <div class="technologies d-flex flex-row">
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-react.png" alt="react" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-swift.png" alt="swift" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-kotlin.png" alt="kotlin" />
            </div>
          </b-col>
        </b-row>
        <b-row v-scrollanimation="'up'" class="service-item align-self-center" cols="1" cols-md="2">
          <b-col>
            <img width="500" class="service-image reguler-shadow reguler-border-radius" src="@/assets/images/services/img-service-3.jpeg" alt="service image" />
          </b-col>
          <b-col v-scrollanimation="'up'" class="d-flex flex-column service-overview text-left">
            <span class="service-title text-primary">Testing &amp; QA</span>
            <h4 class="service-subtitle">
              Bring digital products to market faster with less defects
            </h4>
            <p class="service-desc">
              Our process of test-driven development helps deliver quality digital products avoiding system bugs, UX issues and small errors.
            </p>
            <div class="technologies d-flex flex-row">
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-react.png" alt="react" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-angular.png" alt="angular" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-vue.png" alt="vue" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-springboot.png" alt="springboot" />
              <img width="40" height="40" class="technology-image" src="@/assets/images/logo-zk.png" alt="zk" />
            </div>
          </b-col>
        </b-row>
        <b-row v-scrollanimation="'up'" class="service-item align-self-center flex-md-row-reverse" cols="1" cols-md="2">
          <b-col>
            <img width="500" class="service-image reguler-shadow reguler-border-radius" src="@/assets/images/services/img-service-4.jpeg" alt="service image" />
          </b-col>
          <b-col v-scrollanimation="'up'" class="d-flex flex-column service-overview text-left">
            <span class="service-title text-primary">Cloud &amp; Devops</span>
            <h4 class="service-subtitle">
              Faster continuous delivery with highly reliable infrastructure
            </h4>
            <p class="service-desc">
              Our DevOps brings agility and flexibility to the development resulting in higher quality products and gaining faster time to market.
            </p>
            <div class="technologies d-flex flex-row">
              <img width="130" height="50" class="technology-image gc-partner" src="@/assets/images/GC-Partner.png" alt="google cloud partner" style="height: 50px; width: auto" />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
export default {
  name: "ServiceSection",
  components: {
    BRow,
    BCol,
  },
};
</script>
